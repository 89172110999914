<template>
  <div>
    <select-service-area-subarea @loadLocationPatients="loadLocationPatients" />
    <b-row>
      <b-col cols="4">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            CAMAS
          </b-card-header>
          <!-- chart -->
          <vue-apex-charts
            width="250"
            height="230"
            class="mb-1"
            :options="earningsChart.chartOptions"
            :series="earningsChart.series"
          />
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            TODOS LOS LLAMADOS
          </b-card-header>
          <b-row>
            <b-col
              v-for="(item, index) in allCalled"
              :key="index"
              cols="12"
            >
              <h5 class="ml-2 mb-0">
                {{ item.type }}: {{ item.total }}
              </h5>
              <div
                class="d-flex justify-content-between"
                style="padding: 0.3rem !important"
              >
                <div class="text-center">
                  <div>Atendidos</div>
                  <b-badge
                    :variant="item.variant1"
                    style="font-size: 17px"
                  >
                    {{ item.catered }}
                  </b-badge>
                </div>
                <div class="text-center">
                  <div>No Atendidos</div>
                  <b-badge
                    :variant="item.variant2"
                    style="font-size: 17px"
                  >
                    {{ item.notattended }}
                  </b-badge>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <statistic-diet :statistic-diet="statisticDiet" />
      <!-- <b-col cols="3">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            MEDICAMENTOS
          </b-card-header>
          <b-row>
            <b-col
              v-for="(item, index) in medicines"
              :key="index"
              cols="12"
            >
              <h5 class="ml-2 mb-0">
                {{ item.type }}
              </h5>
              <div class="padding__">
                <b-badge
                  :variant="item.variant"
                  style="font-size: 27.8px"
                >
                  {{ item.total }}
                </b-badge>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col> -->
    </b-row>
    <b-card
      v-if="patientsAltaInfo.length > 0"
      title="Altas medicas"
    >
      <carousel-high-medical
        :patients-alta-info="patientsAltaInfo"
        @altaFisico="altaFisico"
        @altaCancel="altaCancel"
      />
    </b-card>
    <b-card title="Pacientes y ubicaciones">
      <b-row v-if="patients.length > 0">
        <div class="mb-1 d-flex">
          <div
            :class="{ btn_active: isTable }"
            class="btn__ mr-2"
            @click="isActive('table')"
          >
            <v-icon
              name="table"
              class="mr-50"
            />
            <span class="align-middle">Lista</span>
          </div>
          <div
            :class="{ btn_active: isCard }"
            class="btn__ mr-2"
            @click="isActive('card')"
          >
            <v-icon
              name="image"
              class="mr-50"
            />
            <span class="align-middle">Tarjetas</span>
          </div>
          <div
            class="btn_service"
            @click="modalRoomService"
          >
            <feather-icon
              class="mr-50"
              icon="BellIcon"
            />
            <span class="align-middle">Serv. Mantenimiento</span>
          </div>
        </div>
        <b-col md="12">
          <list-card
            v-if="isCard"
            :patients="patients"
            :is-busy="isBusy"
            :is-error="isError"
            :is-empty="isEmpty"
            @transferPatient="transferPatient"
            @altaInfo="altaInfo"
            @modalExamen="modalExamen"
            @modalFindPatient="modalFindPatient"
            @assignMedicines="assignMedicines"
            @reloadDiets="loadOrderByAreaDate"
          />
          <tablee
            v-else
            :patients="patients"
            :is-busy="isBusy"
            :is-error="isError"
            :is-empty="isEmpty"
            @transferPatient="transferPatient"
            @altaInfo="altaInfo"
            @modalExamen="modalExamen"
            @modalFindPatient="modalFindPatient"
            @assignMedicines="assignMedicines"
            @reloadDiets="loadOrderByAreaDate"
            @modalMaintenance="modalMaintenance"
          />
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col
          md="12"
          class="text-center p-4"
        >
          <div class="box_sin_register p-2">
            Sin camas para mostrar. Debe seleccionar una
            <strong>area</strong> con camas registradas.
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-transfer-patiente"
      centered
      title="Formulario de traslado de paciente"
      hide-footer
    >
      <transfer-patient-form
        :location-patient-id="locationPatientId"
        @reloadLocationPatient="reloadLocationPatient"
      />
    </b-modal>
    <b-modal
      id="modal-room-service"
      centered
      title="Solicitar Servicio de matenimiento"
      hide-footer
    >
      <room-service-form :data-serv-area-sub="dataServAreaSub" />
    </b-modal>
    <failureinattentionmodal />
    <!-- Asignar examenes -->
    <b-modal
      id="modal-assign-exam"
      centered
      title="Asignar examenes"
      size="lg"
      hide-footer
    >
      <assign-exam-modal :patient="patient" />
    </b-modal>
    <!-- Asignar medicinas -->
    <b-modal
      id="modal-assign-medicine"
      centered
      title="Asignar medicamentos"
      size="lg"
      hide-footer
    >
      <assign-medicine :patient="patient" />
    </b-modal>
    <!-- Busquedad de paciente -->
    <b-modal
      id="modal-find-patient"
      centered
      title="Busqueda de paciente"
      size="lg"
      hide-footer
    >
      <find-patient
        :location="location"
        @registerBed="registerBed"
      />
    </b-modal>
    <!-- Registar cama -->
    <b-modal
      id="modal-register-bed"
      centered
      title="Registro cama paciente"
      size="lg"
      hide-footer
    >
      <register-bed :patient-bed="patientBed" />
    </b-modal>
    <!-- Detalle mantenimiento -->
    <b-modal
      id="modal-maintenance"
      centered
      title="Detalle de mantenimiento"
      hide-footer
    >
      <form-detail-service :record="service" />
    </b-modal>
  </div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BBadge,
	VBModal,
	BCardHeader,
	BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'
import { v4 as uuidv4 } from 'uuid'

// Components
import mqtt from 'mqtt'
import moment from 'moment'
import TransferPatientForm from './components/TransferPatientForm'
import failureinattentionmodal from './components/FailureInAttentionModal'
import tablee from './components/LocationTable'
import assignExamModal from './components/AssignExamModal'
import ListCard from './components/ListCard'
import RoomServiceForm from './components/RoomServiceForm'
import CarouselHighMedical from './components/carousel/CarouselHighMedical'
import FindPatient from './components/FindPatient'
import RegisterBed from './components/RegisterBed'
import AssignMedicine from './components/AssignMedicine'
import SelectServiceAreaSubarea from '@/components/SelectServiceAreaSubarea'
import { optionsMqtt } from '@/config/optionMqtt'
import StatisticDiet from './components/statistics/StatisticDiet'
import FormDetailService from './components/FormDetailService'

moment.locale('es')

const $earningsStrokeColor1 = '#7367F0'
const $earningsStrokeColor2 = '#FF9F43'
const $earningsStrokeColor3 = '#ff4242'

export default {
	name: 'PatientDashboard',
	inject: ['patientsRepository', 'patientBedRepository'],
	components: {
		BCard,
		BRow,
		BCol,
		BCardHeader,
		BBadge,
		BModal,
		VueApexCharts,
		tablee,
		failureinattentionmodal,
		assignExamModal,
		RoomServiceForm,
		ListCard,
		CarouselHighMedical,
		SelectServiceAreaSubarea,
		TransferPatientForm,
		FindPatient,
		RegisterBed,
		AssignMedicine,
		StatisticDiet,
		FormDetailService,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			earningsChart: {
				series: [0, 0, 0],
				chartOptions: {
					chart: {
						type: 'donut',
					},
					legend: {
						show: true,
						position: 'bottom',
					},
					dataLabels: {
						formatter: (val, { seriesIndex, dataPointIndex, w }) =>
							w.config.series[seriesIndex],
						textAnchor: 'middle',
						distributed: false,
						offsetX: 0,
						offsetY: 0,
						style: {
							fontSize: '14px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							fontWeight: 'bold',
							colors: undefined,
						},
						background: {
							enabled: true,
							foreColor: '#fff',
							padding: 4,
							borderRadius: 2,
							borderWidth: 1,
							borderColor: '#fff',
							opacity: 0.9,
							dropShadow: {
								enabled: false,
								top: 1,
								left: 1,
								blur: 1,
								color: '#000',
								opacity: 0.45,
							},
						},
						dropShadow: {
							enabled: false,
							top: 1,
							left: 1,
							blur: 1,
							color: '#000',
							opacity: 0.45,
						},
					},
					plotOptions: {
						pie: {
							donut: {
								labels: {
									show: true,
									total: {
										show: true,
										showAlways: true,
										label: 'Total',
										fontSize: '22px',
										fontWeight: 900,
										color: '#373d3f',
										formatter(w) {
											return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
										},
									},
								},
							},
						},
					},
					labels: ['Disponibles', 'Ocupadas', 'Fuera de servicio'],
					colors: [
						$earningsStrokeColor1,
						$earningsStrokeColor2,
						$earningsStrokeColor3,
					],
				},
			},
			allCalled: [
				{
					type: 'Pacientes',
					total: 40,
					catered: 20,
					notattended: 20,
					variant1: 'primary',
					variant2: 'danger',
				},
				{
					type: 'Código Azul',
					total: 30,
					catered: 10,
					notattended: 20,
					variant1: 'primary',
					variant2: 'danger',
				},
				{
					type: 'Código Rojo',
					total: 10,
					catered: 0,
					notattended: 10,
					variant1: 'primary',
					variant2: 'danger',
				},
			],
			medicines: [
				{
					type: 'Programadas',
					total: 60,
					variant: 'success',
				},
				{
					type: 'Ejecutadas',
					total: 40,
					variant: 'primary',
				},
				{
					type: 'Devoluciones',
					total: 10,
					variant: 'danger',
				},
			],
			patients: [],
			patientsAltaInfo: [],
			statisticDiet: [
				{
					type: 'Desayunos',
					total: 0,
					delivered: 0,
					variant: 'success',
					value: 0,
				},
				{
					type: 'Almuerzos',
					total: 0,
					delivered: 0,
					variant: 'primary',
					value: 0,
				},
				{
					type: 'Cenas',
					total: 0,
					delivered: 0,
					variant: 'danger',
					value: 0,
				},
			],
			isCard: false,
			isTable: true,
			isBusy: false,
			isError: false,
			isEmpty: false,
			locationPatientId: null,
			areaId: null,
			dataServAreaSub: null,
			patient: null,
			location: null,
			patientBed: null,
			brand: null,
			brand2: null,
			area: null,
			service: null,
			current_day: (moment(), moment()).format('YYYY-MM-DD'),
		}
	},

	created() {},

	async mounted() {
		const me = this
	},

	methods: {
		isActive(item) {
			const me = this
			if (item == 'card') {
				me.isCard = true
				me.isTable = false
			} else {
				me.isCard = false
				me.isTable = true
			}
		},
		modalRoomService() {
			this.$bvModal.show('modal-room-service')
		},
		loadLocationPatients(items, data) {
			const me = this
			me.dataServAreaSub = data
			me.areaId = me.dataServAreaSub.areaId
			me.patientsAltaInfo = []
			me.patients = []
			items.forEach(el => {
				if (el.location_patient && el.location_patient.state == '2') {
					me.patientsAltaInfo.push({
						items: [
							{
								title: el.name,
								patient: el.patient.first_name + ' ' + el.patient.last_name,
								identification: el.patient.document_number,
								// time: '2 minutos',
							},
						],
						fields: [
							{
								key: 'title',
								label: 'Cama',
							},
							{
								key: 'patient',
								label: 'Paciente',
							},
							{
								key: 'identification',
								label: 'Identificación',
							},
							// {
							// 	key: 'time',
							// 	label: 'Tiempo transcurrido',
							// },
							// {
							// 	key: 'process',
							// 	label: 'Proceso de alta',
							// },
						],
						locationPatientId: el.location_patient.id,
						areaId: el.area_id,
					})
				} else if (
					!el.location_patient ||
					(el.location_patient && el.location_patient.state == '1')
				) {
					me.patients.push({
						...el,
						orders: [],
						exams: [],
						examsTotal: [],
						device: null,
					})
				}
			})
			me.loadOrderByAreaDate()
			me.loadExamByAreaDate()
			me.statisticsBed()
			me.startMqttClient()
			me.listenSocket()
		},
		loadLocationPatients2(items) {
			const me = this
			me.patientsAltaInfo = []
			me.patients = []
			items.forEach(el => {
				if (el.location_patient && el.location_patient.state == '2') {
					me.patientsAltaInfo.push({
						items: [
							{
								title: el.name,
								patient: el.patient.first_name + ' ' + el.patient.last_name,
								identification: el.patient.document_number,
							},
						],
						fields: [
							{
								key: 'title',
								label: 'Cama',
							},
							{
								key: 'patient',
								label: 'Paciente',
							},
							{
								key: 'identification',
								label: 'Identificación',
							},
							// {
							// 	key: 'process',
							// 	label: 'Proceso de alta',
							// },
						],
						locationPatientId: el.location_patient.id,
						areaId: el.area_id,
					})
				} else if (
					!el.location_patient ||
					(el.location_patient && el.location_patient.state == '1')
				) {
					me.patients.push({
						...el,
						orders: [],
						exams: [],
						examsTotal: [],
					})
				}
			})
			me.loadOrderByAreaDate()
			me.loadExamByAreaDate()
			me.statisticsBed()
		},
		async loadOrderByAreaDate() {
			const me = this
			// me.patients2 = []
			let orderPatients = null
			me.statisticDiet = []
			try {
				me.isBusy = true
				me.isError = false
				me.isEmpty = false
				const { data } = await me.patientsRepository.getAllByAreaDate(
					me.dataServAreaSub.areaId,
					me.current_day,
				)
				orderPatients = data
				if (orderPatients.length > 0) {
					orderPatients.forEach(el => {
						me.patients.forEach(x => {
							if (x.patient && el.patient_id == x.patient.id) {
								x.orders.push({
									id: el.id,
									type: el.menu.type_menu,
									status: !!el.arrival_at,
								})
							}
						})
					})
				}

				me.patients.forEach(el => {
					el.orders.sort((a, b) => {
						if (a.type > b.type) {
							return 1
						}
						if (a.type < b.type) {
							return -1
						}
						return 0
					})
				})
			} catch (error) {
				const code = error.response.status
				if (code == 404) {
					me.isEmpty = true
				} else {
					me.isError = true
				}
			} finally {
				me.isBusy = false
			}
			me.statisticsDiet()
		},
		async loadExamByAreaDate() {
			const me = this
			let examPatients = null
			try {
				const { data } = await me.patientsRepository.getAllExamByAreaDate(
					me.dataServAreaSub.areaId,
					me.current_day,
				)

				examPatients = data
				if (examPatients.length > 0) {
					examPatients.forEach(el => {
						me.patients.forEach(x => {
							if (x.patient && el.patient_id == x.patient.id) {
								x.examsTotal.push(el)
								if (el.made_date) {
									x.exams.push(el)
								}
							}
						})
					})
				}
			} catch (error) {
				// console.log(error)
			}
		},
		transferPatient(id, area_id) {
			const me = this
			me.areaId = area_id
			me.locationPatientId = id
			me.$bvModal.show('modal-transfer-patiente')
		},
		async altaInfo(id, area_id) {
			const me = this
			try {
				await me.patientBedRepository.altaInfo(id)
			} catch (error) {
				// console.log(error)
			}
		},
		async altaFisico(id, area_id) {
			const me = this
			me.areaId = area_id
			try {
				await me.patientBedRepository.altaFisico(id)
			} catch (error) {
				// console.log(error)
			}
		},
		async altaCancel(id, area_id) {
			const me = this
			me.areaId = area_id
			try {
				await me.patientBedRepository.altaCancel(id)
			} catch (error) {
				// console.log(error)
			}
		},
		async reloadLocationPatient() {
			const me = this
			try {
				const res = await me.patientBedRepository.getAllLocationPatient(
					me.areaId,
				)
				const items = []
				res.forEach(el => {
					el.list.forEach(elem => {
						items.push({ ...elem, area_id: el.area_id })
					})
				})
				me.loadLocationPatients2(items)
			} catch (error) {
				// console.log(error)
			}
		},
		modalExamen(item) {
			const me = this
			me.patient = item
			me.$bvModal.show('modal-assign-exam')
		},
		assignMedicines(item) {
			const me = this
			me.patient = item
			me.$bvModal.show('modal-assign-medicine')
		},
		modalFindPatient(item) {
			const me = this
			me.location = item
			me.$bvModal.show('modal-find-patient')
		},
		registerBed(id) {
			const me = this
			me.patientBed = {
				patientId: id,
				locationId: me.location.id,
				nameBed: me.location.name,
			}

			me.areaId = me.location.area_id
			me.$bvModal.show('modal-register-bed')
		},
		startMqttClient() {
			const me = this

			me.brand2 = me.dataServAreaSub.service.text.split(' ').join('_')
			me.brand = me.dataServAreaSub.brand.name.split(' ').join('_')
			me.area = me.dataServAreaSub.area.text.split(' ').join('_')

			const topic = `${me.brand}/${me.area}/+/#`
			const connectUrl =
				'wss://' +
				optionsMqtt.host +
				':' +
				optionsMqtt.port +
				optionsMqtt.endpoint

			try {
				const uuid = uuidv4()
				me.client = mqtt.connect(connectUrl, {
					...optionsMqtt,
					clientId: uuid,
				})
			} catch (error) {
				// console.error(error)
			}

			me.client.on('connect', () => {
				console.log('Connection succeeded')

				me.client.subscribe(topic, { qos: 0 }, err => {
					if (err) {
						console.log('Error in subscription', err)
						return
					}

					console.log('Subscription success')
				})
			})

			me.client.on('error', error => {
				//  console.log('Connection failed', error)
			})

			me.client.on('reconnect', error => {
				// console.log('Reconnecting', error)
			})

			me.client.on('message', (topico, message) => {
				// console.log('topic =>', topico)
				try {
					const messageReceived = JSON.parse(message.toString())
					if (messageReceived.llamado == 'Alta') {
						me.patients.forEach(el => {
							if (el.location_patient) {
								el.devices.forEach(x => {
									if (
										x.device_type_id ==
											'a92f1b6a-9067-48d8-a00e-66802b0a2af7' &&
										x.mac == messageReceived.mac
									) {
										me.reloadLocationPatient()
									}
								})
							}
						})
					} else {
						me.patients.forEach(el => {
							el.devices.forEach(x => {
								if (
									x.device_type_id == '9ff27a35-7d3c-4b18-a810-4cd36173184e' &&
									x.mac == messageReceived.mac
								) {
									const item = el
									item.device = messageReceived.sensor[0].values[0].value
								}
							})
						})
					}
				} catch (error) {
					// console.lgo(error)
				}
			})
		},
		statisticsDiet() {
			const me = this
			if (me.isEmpty) {
				me.statisticDiet.push(
					{
						type: 'Desayunos',
						total: 0,
						delivered: 0,
						variant: 'success',
						value: 0,
					},
					{
						type: 'Almuerzos',
						total: 0,
						delivered: 0,
						variant: 'primary',
						value: 0,
					},
					{
						type: 'Cenas',
						total: 0,
						delivered: 0,
						variant: 'danger',
						value: 0,
					},
				)
			} else {
				me.findStatisticDiet()
			}
		},
		findStatisticDiet() {
			const me = this
			const diet1 = []
			const diet2 = []
			const diet3 = []
			const dietD1 = []
			const dietD2 = []
			const dietD3 = []

			me.patients.forEach(x => {
				if (x.orders.length > 0) {
					x.orders.forEach(el => {
						if (el.type == '1') {
							if (el.status) {
								dietD1.push(el.status)
							}
							diet1.push(el.status)
						} else if (el.type == '2') {
							if (el.status) {
								dietD2.push(el.status)
							}
							diet2.push(el.status)
						} else if (el.type == '3') {
							if (el.status) {
								dietD3.push(el.status)
							}
							diet3.push(el.status)
						}
					})
				}
			})

			me.statisticDiet.push(
				{
					type: 'Desayunos',
					total: 0,
					delivered: 0,
					variant: 'success',
					value: 0,
				},
				{
					type: 'Almuerzos',
					total: 0,
					delivered: 0,
					variant: 'primary',
					value: 0,
				},
				{
					type: 'Cenas',
					total: 0,
					delivered: 0,
					variant: 'danger',
					value: 0,
				},
			)
			me.statisticDiet.forEach(el => {
				const element = el
				if (element.type == 'Desayunos') {
					element.delivered = dietD1.length
					element.total = diet1.length
					element.value = (dietD1.length / diet1.length) * 100
				} else if (element.type == 'Almuerzos') {
					element.delivered = dietD2.length
					element.total = diet2.length
					element.value = (dietD2.length / diet2.length) * 100
				} else {
					element.delivered = dietD3.length
					element.total = diet3.length
					element.value = (dietD3.length / diet3.length) * 100
				}
			})
		},
		statisticsBed() {
			const me = this
			const occupied = []
			const available = []
			const outOfService = []

			me.patients.forEach(el => {
				if (el.location_patient) {
					occupied.push(el)
				} else if (!el.location_patient && el.bed_request && el.bed_request.length > 0) {
					const bedActive = el.bed_request.find(x => x.finished_date == null)
					if (bedActive) outOfService.push(el)
				} else if (!el.location_patient && el.maintenance_service && el.maintenance_service.length > 0) {
					const maintenanceActive = el.maintenance_service.find(x => x.state == '1')
					if (maintenanceActive) outOfService.push(el)
				} else {
					available.push(el)
				}
			})

			me.earningsChart.series = [available.length, occupied.length, outOfService.length]
		},
		sendCall(mac, call) {
			const me = this

			const date = new Date()
			const timestamp = moment(date).format('YYYY-MM-DD HH:mm:ss')

			const payload = {
				area: me.area,
				empresa: me.brand,
				llamado: call,
				mac: mac,
				sede: me.brand2,
				timestamp: timestamp,
			}

			const payload2 = JSON.stringify(payload)
			const topic = `${me.brand}/${me.area}`

			me.client.publish(topic, payload2, { qos: 0 }, error => {
				if (error) {
					console.log('Publish error', error)
				}
			})
		},
		fillExam(exam) {
			const me = this
			me.patients.forEach(el => {
				if (el.patient && el.patient.id == exam.patient_id) {
					el.examsTotal.push(el)
					if (el.made_date) {
						el.exams.push(el)
					}
				}
			})
		},
		listenSocket() {
			const me = this
			window.Echo.channel(`menu-order-${me.areaId}`)
				.listen('.menu.order.created', e => {
					me.reloadLocationPatient()
				})
				.listen('.menu.order.status', e => {
					me.reloadLocationPatient()
				})
			window.Echo.channel(`exam-patient-${me.areaId}`).listen(
				'.exam.patient.created',
				e => {
					const exam = JSON.parse(e.data)
					me.fillExam(exam)
				},
			)
			window.Echo.channel(`location-patient-${me.areaId}`)
				.listen('.location.patient.departure.info', e => {
					me.reloadLocationPatient()
				})
				.listen('.location.patient.created', async e => {
					me.reloadLocationPatient()
				})
				.listen('.location.patient.cancel', e => {
					me.reloadLocationPatient()
				})
				.listen('.location.patient.moved', async e => {
					me.reloadLocationPatient()
				})
				.listen('.location.patient.departure', e => {
					me.reloadLocationPatient()
				})
			window.Echo.channel(`location-${me.areaId}`)
				.listen('.location.outservice.created', e => {
					me.reloadLocationPatient()
				})
				.listen('.location.outservice.updated', e => {
					me.reloadLocationPatient()
				})
			window.Echo.channel('change-turn').listen(
				'.turn.changed',
				e => {
					me.reloadLocationPatient()
				},
			)
		},
		modalMaintenance(item) {
			const me = this
			me.service = item
			me.$bvModal.show('modal-maintenance')
		},
	},
}
</script>

<style lang="scss">
.container-entregadas {
	height: 1rem;
	width: 100%;
	background-color: #f5f5f5;
	border-radius: 0.5rem;
	margin: 0.5rem 0rem 0.6rem 0rem;
	overflow: hidden;
}
.progress-entregadas {
	height: 100%;
	width: 33%;
	&.delivered {
		background-color: #28c76f;
	}
	&.nodelivered {
		background-color: #fb9f43;
	}
}

.card__ {
	box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3),
		0 4px 15px 0 rgba(133, 132, 132, 0.15);
}

.progress {
	height: 1.15rem !important;
}

.padding__ {
	padding: 0.6rem !important;
}

.btn__ {
	background-color: #fff;
	border: 1px solid #7367f0;
	padding: 8px;
	color: #7367f0;
	border-radius: 0.5rem;
	cursor: pointer;
}

.btn_active {
	background-color: #7367f0;
	color: #fff;
}

.btn_service {
	background-color: #28c76f;
	padding: 8px;
	color: #ffff;
	border-radius: 0.5rem;
	cursor: pointer;
}

.box_sin_register {
	border: 4px solid #ea5455;
	color: #5e5873;
	font-size: 16px;
	border-radius: 0.4rem;
}
</style>
